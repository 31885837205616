export default [
    {
        to: {name: 'admin.index'},
        icon: 'mdi-home',
        text: 'Dashboard',
    },
    {
        to: {name: 'admin.price-plans.index'},
        icon: 'mdi-application-cog',
        text: 'PricePlans',
    },
    {
        to: {name: 'admin.users.index'},
        icon: 'mdi-account',
        text: 'Users',
    },
    {
        to: {name: 'admin.videos.index'},
        icon: 'mdi-message-video',
        text: 'Videos',
    },
    {
        to: {name: 'admin.guides.index'},
        icon: 'mdi-file-document-multiple',
        text: 'Guides',
    },
    {
        to: {name: 'admin.social-graphics.index'},
        icon: 'mdi-file-document-multiple',
        text: 'SocialGraphics',
    },
    {
        to: {name: 'admin.infographics.index'},
        icon: 'mdi-file-chart-outline',
        text: 'Infographics',
    },
    {
        to: {name: 'admin.market-reports.index'},
        icon: 'mdi-file-chart-outline',
        text: 'MarketReports',
    },
    {
        to: {name: 'admin.tags.index'},
        icon: 'mdi-file-chart-outline',
        text: 'Tags',
    },
    {
        to: {name: 'admin.industries.index'},
        icon: 'mdi-file-chart-outline',
        text: 'Industries',
    },
]
