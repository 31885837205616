<template>
    <div class="d-flex flex-grow-1">
        <top-menu @toggleDrawer="mini = ! mini" :is-dashboard="true" />
        <admin-drawer-menu :mini="mini"  />
        <v-main>
            <v-container v-show="loading" class="fill-height">
                <v-col class="text-center">
                    <v-progress-circular :size="50" color="primary" indeterminate/>
                </v-col>
            </v-container>

            <v-container v-show="!loading">
                <v-row>
                    <v-col class="text-left">
                        <slot name="left-tools"/>
                    </v-col>
                    <v-col class="text-right">
                        <slot name="right-tools"/>
                    </v-col>
                </v-row>
                <slot> <!--CONTENT--> </slot>
            </v-container>
        </v-main>
    </div>
</template>

<script>
    import AdminDrawerMenu from "../components/admin/AdminDrawerMenu";
    import TopMenu from "../components/TopMenu";

    export default {
        name: "admin-dashboard-layout",
        components: {TopMenu, AdminDrawerMenu},
        props: {
            loading: {
                type: Boolean,
                default: false,
            }
        },
        data: function () {
            return {
                mini: false,
            }
        },
    }
</script>

<style scoped>

</style>
