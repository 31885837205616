<template>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                small
                v-bind="attrs"
                v-on="on"
            >
                {{$t('other.' + other.dlt)}}
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="headline">
                {{$t('other.' + other.cnfm)}}
            </v-card-title>

            <v-card-text>
                {{$t('other.' + other.strothone)}} "{{model.name}}"? {{$t('other.' + other.strothtwo)}}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="red darken-1"
                    text
                    @click="dialog = false"
                >
                    {{$t('other.' + other.cncl)}}
                </v-btn>

                <v-btn
                    color="green darken-1"
                    text
                    @click="handleDelete"
                >
                    {{$t('other.' + other.cnfrm)}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations} from "vuex";
import other from "@/configs/other";


export default {
    name: "DeleteDialog",
    props: ['model', 'type'],
    data: function () {
        return {
            dialog: false,
            other
        }
    },
    methods: {
        async handleDelete() {
            let res = await window.axios.delete('/api/'+this.type+'/'+this.model.id)
            console.log(res)
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})
            this.dialog = false
            this.$emit("success")
        },
        ...mapMutations(['showSnackBar']),
    }
}
</script>

<style scoped>

</style>
