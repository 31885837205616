<template>
    <v-col class="d-flex" cols="6" sm="3">
        <v-select
            :items="items"
            v-model="selected"
            label="Timeframe"
            outlined
            @change="select"
            item-text="label"
            item-value="value"
        ></v-select>
    </v-col>
</template>

<script>
export default {
    name: "TimeframeSelect",
    props: {
        pushToQuery: {
            type: Boolean,
            default: true
        },
        queryParamName: {
            type: String,
            default: 'lastDays'
        }
    },
    data() {
        return {
            selected: {label: 'All Time', value: null},
            items: [
                {label: 'All Time', value: null},
                {label: 'Last 7 days', value: 7},
                {label: 'Last 30 days', value: 30},
                {label: 'Last 6 months', value: 180},
                {label: 'Last year', value: 365},
            ],
        }
    },
    methods: {
        select() {
            this.$emit('select', this.selected)

            if (this.pushToQuery) {
                this.updateQuery()
            }
        },
        updateQuery() {
            const query = Object.assign({}, this.$route.query)

            query[this.queryParamName] = this.selected

            if (query[this.queryParamName] === null) {
                delete query[this.queryParamName]
            }

            this.$router.push({query})
        }
    },
    async mounted() {
        const days = this.$route.query[this.queryParamName];

        this.selected = days ? parseInt(days) : null
    },
    watch: {
        "$route.query": async function (val, oldVal) {
            if (val !== oldVal) {
                const days = val[this.queryParamName];

                this.selected = days ? parseInt(days) : null
            }
        }
    }
}
</script>

<style scoped>

</style>
